import request from '@/utils/request';

// 论文差距评测表单提交
export const citationSubmit = (paramMap) => {
    // return request({
    //     url: '/query/high/citation/submit',
    //     method: 'post',
    //     data: paramMap
    // })

    return request({
        url: '/hightedSubject',
        method: 'post',
        params: paramMap,
        data: paramMap
    })
}